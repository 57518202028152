// import './App.css';
import { Component } from 'react';

const data = [
  { name: 'ข้าวผัด', category: 'อาหารคาว', img:'https://img.wongnai.com/p/1920x0/2019/12/19/d5537700a7274ac09964b6a51dd0a9f6.jpg', detail: 'Fried rice is a classic dish made by stir-frying cooked rice with a variety of ingredients like vegetables, eggs, and sometimes meat or seafood. The dish is seasoned with soy sauce, garlic, and other spices, resulting in a savory and aromatic flavor. It’s a versatile dish that can be customized to suit different tastes and is commonly enjoyed as a main course or a side dish.' },
  { name: 'บัวลอย', category: 'อาหารหวาน',img:'https://f.ptcdn.info/557/035/000/1442537793-IMG34862-o.jpg', detail: 'Bua Loy is a traditional Thai dessert consisting of small, soft rice flour balls floating in warm, sweet coconut milk. The rice balls are often colored with natural dyes and filled with ingredients like black sesame or taro. The coconut milk is lightly sweetened and sometimes flavored with pandan leaves, giving it a rich, aromatic taste. This dessert is enjoyed for its creamy texture and comforting warmth.' },
  { name: 'ส้มตำ', category: 'อาหารคาว',img:'https://i.pinimg.com/736x/0e/84/85/0e84856848eb841b85d5541f301f124b.jpg', detail: 'Som Tum is a popular Thai salad known for its bold and refreshing flavors. Made primarily from shredded green papaya, it is combined with cherry tomatoes, green beans, peanuts, and dried shrimp. The salad is dressed with a tangy mixture of lime juice, fish sauce, palm sugar, and chili peppers, giving it a spicy and zesty kick. Som Tum is often served with sticky rice and grilled meats, making it a vibrant and crunchy addition to any Thai meal.' },
  { name: 'แกงส้ม', category: 'อาหารคาว',img:'https://mpics.mgronline./pics/Images/566000004578002.JPEG', detail: 'Gaeng Som, also known as Thai sour curry, is a distinctive Thai dish characterized by its tangy and spicy flavor. The curry is made with a base of tamarind paste and a variety of fresh vegetables, such as bamboo shoots, carrots, and green beans. It often includes fish or shrimp, adding depth to the dish. Gaeng Som is known for its bright, tangy taste and is typically enjoyed with steamed rice, providing a delicious contrast to its sour and spicy profile.' },
];

function App() {
  const food1 = data[0]
  const food2 = data[1]
  const food3 = data[2]
  const food4 = data[3]

  return (
    <div>

      <div className='App'>
        <h1>โหวตอาหาร</h1>
        <VoteBox food = {food1}/>
        <VoteBox food = {food2}/>
        <VoteBox food = {food3}/>
        <VoteBox food = {food4}/>
      </div>

      <style jsx>{`
        .App {
          display: flex;
          flex-direction: column;
          text-align: center;
          background-color: #696969;
          min-height: 100vh;
          align-items: center;
          justify-content: center;
        }

        .App h1 {
          color: #FECE07;
        }
      `}</style>

    </div>
  );
}


class VoteBox extends Component{

  render(){
    return(
      <div>

        <div className='vote-box'>
          <div className='vote-box-content'>
            <div>
            <h2>{this.props.food.category}</h2>
            <h3>{this.props.food.name}</h3>
            <p>{this.props.food.detail}</p>
            </div>
          </div>
          <div className='vote-box-image'>
            <img src={this.props.food.img}></img>
          </div>
          <div className='vote-box-button'>
              <VoteButton/>
          </div>
        </div>


        <style jsx>{`
          .vote-box {
            width: 900px;
            height: auto;
            background-color: #FCECD6;
            display: grid;
            grid-template-columns: 2fr 1fr;
            border: 2px solid;
            border-radius: 10px;
            margin-bottom: 10px
          }

          .vote-box  h2{
            color: black;
          }

          .vote-box-content {
            text-align: start;
            padding: 30px 0px 10px 30px;
          }

          .vote-box-image {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            height: 100%;
          }

          .vote-box-image img {
            width: 100%;
            height: auto%;
            object-fit: cover;
            border-radius: 6px;
          }                     
            
          .vote-box-button {
            grid-column: 1 / 3;
            margin: 20px 0px 30px 0px;
          }     
        `}</style>
      </div>
    )
  }
}

class VoteButton extends Component{

  state ={
    score: 0,
  }

  handleVote = () => {
      const newScore = this.state.score + 1;
      if (newScore > 10) {
        alert("Cannot Vote More");
      }else {
        this.setState({ score: newScore });
        console.log(this.state.score)
      }

  };

  handleUnvote = () => {
      const newScore = this.state.score - 1;
      if (newScore < 0){
        alert("Cannot Unvote");
      }else {
        this.setState({ score: newScore });
        console.log(this.state.score)
      }
  }

  render(){
    return(
      <div>

        <div className='vote-button'>
          <button onClick={this.handleVote}>Click to Vote</button>
          <ScoreDisplay score = {this.state.score}/>
          <button onClick={this.handleUnvote}>Click to Unote</button>
        </div>

        <style jsx>{`
          button {
            border: 1px solid;
            border-radius: 5px;
            padding: 10px 20px 10px 20px;
            margin-left: 20px;
            margin-right: 20px;
            height: 50%
          }
          .vote-button {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
          }
        `}</style>        
      </div>
    )
  }
}

class ScoreDisplay extends Component{
  render(){
    const score = this.props.score
    let displayText;
    if (score === 0){
      displayText = "MIN"
    } else if (score === 10){
      displayText = "MAX"
    } else {
      displayText = `${score}`
    }

    return(
      <div>
        <div className='display-score'>
            <p>{displayText}</p>
        </div>


        <style jsx>{`
          .display-score {
            display: flex;
            align-items: center;
            justify-content: center;          
            border: 3px solid;
            border-radius: 15px;
            background-color: #84C441;
            color: #64426C;
            height: 70px;
            width: 100px;
          }
          .display-score p {
            font-weight: bold;
            margin: 0px;
            color: #7955A3;
            font-size: 35px
          }
        `}</style>

      </div>
    )
  }
}

export default App;
